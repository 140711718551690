<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<link rel="stylesheet" href="@/layui/css/layui.css">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">我的地址管理</h1>
				<div class="Personalsection">

					<form class="layui-form" lay-filter="example">
						<div id="distpicker2" class="layui-form-item" data-toggle="distpicker" data-autoselect="3">

							<label class="layui-form-label" style="float: left;width: initial;padding:9px 0"> <span
									class="redxx">*</span>收货地址</label>
							<div class="submit_address">
								<div class="layui-input-block" style="margin-bottom: 6px;">
									<select id="address_sheng" name="quiz1" lay-filter="brickType1"></select>
								</div>
								<div class="layui-input-block" style="margin-bottom: 6px;">
									<select id="address_shi" name="quiz2" lay-filter="brickType2"></select>
								</div>
								<div class="layui-input-block" style="margin-bottom: 6px;">
									<select id="address_qv" name="quiz3" lay-filter="brickType3"></select>
								</div>
							</div>
						</div>

						<div class="addressdetail">

							<label class="layui-form-label" style="float: left;width: initial;padding:9px 0"> <span
									class="redxx">*</span>详细地址</label>
							<div class="submit_address">
								<div class="layui-input-block placeholderclass" style="margin-bottom: 6px;">
									<input type="text" class="layui-input" v-model="dataValues.Address"
										placeholder="请填写详细地址">
								</div>
							</div>
						</div>
						<div class="addressname">
							<label class="layui-form-label" style="float: left;width: initial;padding:9px 0"> <span
									class="redxx">*</span>收货人姓名</label>
							<div class="submit_address">
								<div class="layui-input-block placeholderclass" style="margin-bottom: 6px;">
									<input type="text" class="layui-input" v-model="dataValues.Consignee"
										placeholder="请填写收货人姓名">
								</div>
							</div>
						</div>
						<div class="addressphone">
							<label class="layui-form-label" style="float: left;width: initial;padding:9px 0"> <span
									class="redxx">*</span>手机号码</label>
							<div class="submit_address">
								<div class="layui-input-block placeholderclass" style="margin-bottom: 6px;">
									<input type="text" class="layui-input" v-model="dataValues.Mobile"
										placeholder="请填写手机号码">
								</div>
							</div>
						</div>
						<div class="layui-form-item" pane="">
							<div class="layui-input-block">
								<div @click="defaultaddress(dataValues.IsDefault)" style="display: inline-block;" v-if="dataValues.IsDefault==0 && defaultinput">
									<div class="layui-unselect layui-form-checkbox" lay-skin="primary"><span>设置为默认收货地址</span><i class="layui-icon layui-icon-ok"></i></div>
								</div>
								<div @click="defaultaddress(dataValues.IsDefault)" style="display: inline-block;" v-if="dataValues.IsDefault==1 && defaultinput">
									<div class="layui-unselect layui-form-checkbox layui-form-checked" lay-skin="primary"><span>设置为默认收货地址</span><i class="layui-icon layui-icon-ok"></i></div>
								</div>
							</div>
						</div>
						<div class="layui-form-item">
							<div class="layui-input-block">
								<button type="button" class="layui-btn" lay-submit="" @click="OpercateUserAddress"
									v-if="layuibtn">立即提交</button>
								<button type="button" class="layui-btn" lay-submit="" @click="EditUserAddress"
									v-else>立即修改</button>
							</div>
						</div>
					</form>
					<div class="next-table next-table-medium">
						<table role="table">
							<thead class="next-table-header">
								<tr>
									<th rowspan="1" class="next-table-cell next-table-header-node" role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-col="0">收货人</div>
									</th>
									<th rowspan="1" class="next-table-cell next-table-header-node" role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-col="1">所在地区</div>
									</th>
									<th rowspan="1" class="next-table-cell next-table-header-node" role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-col="2">详细地址</div>
									</th>
									<th rowspan="1" class="next-table-cell next-table-header-node" role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-col="3">电话/手机</div>
									</th>
									<th rowspan="1" class="next-table-cell next-table-header-node" role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-col="4">操作</div>
									</th>
									<th rowspan="1" class="next-table-cell next-table-header-node" role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-col="5"></div>
									</th>
								</tr>
							</thead>
							<tbody class="next-table-body" id="next-table-body" v-if="addlist.length>0">
								<tr class="next-table-row first" role="row" 
									v-for="(item,index) in addlist" :key="index">
									<td data-next-table-col="0" data-next-table-row="0" class="next-table-cell first"
										role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-row="0">
											{{item.Consignee}}
										</div>
									</td>
									<td data-next-table-col="1" data-next-table-row="0" class="next-table-cell"
										role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-row="0"><span>
												{{item.Province}}&nbsp;{{item.City}}&nbsp;{{item.Area}}
											</span>
										</div>
									</td>
									<td data-next-table-col="2" data-next-table-row="0" class="next-table-cell"
										role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-row="0">
											{{item.Address}}
										</div>
									</td>
									<td data-next-table-col="4" data-next-table-row="0" class="next-table-cell"
										role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-row="0"><span>
												{{item.Mobile}}<br></span></div>
									</td>
									<td data-next-table-col="5" data-next-table-row="0" class="next-table-cell"
										role="gridcell">
										<div class="next-table-cell-wrapper" data-next-table-row="0">
											<div class="tAction">
												<div class="disable-action-con">
													<a class="t-change" target="_self" @click="EditAddress(item)">修改</a>
													<span class=" t-line">|</span>
												</div>
												<a class="t-change" target="_self"
													@click="DelUserAddress(item.Id)">删除</a>
											</div>
										</div>
									</td>
									<td data-next-table-col="6" data-next-table-row="0" class="next-table-cell last"
										role="gridcell" v-if="index==0">
										<div class="next-table-cell-wrapper" data-next-table-row="0">
											<div><span class="t-default defaultclass">默认地址</span></div>
										</div>
									</td>
									<td data-next-table-col="6" data-next-table-row="1" class="next-table-cell last"
										role="gridcell" v-else>
										<div class="next-table-cell-wrapper" data-next-table-row="1">
											<div><span class="t-setDefault defaultclass"
													@click="setAddressDefault(item.Id)">设为默认</span></div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

			</div>

			<!-- 我的订单头部END -->

			<!-- 我的订单主要内容 -->

			<!-- 我的订单主要内容END -->
		</div>
	</div>
</template>
<script>
	import {
		distpicker
	} from '@/js/distpicker.js'

	// import layui from '@/layui/layui.js'
	// import '@/layui/css/layui.css'
	export default {
		name: 'Order',
		data() {
			return {
				dataitems: null,
				orders: [], // 订单列表
				total: [], // 每个订单的商品数量及总价列表
				dataValues: {
					Id: 0,
					Consignee: '', //联系人
					Mobile: '', //手机号
					Province: '', //省
					City: '', //市
					Area: '', //区
					Address: '', //详细地址
					IsDefault: 0 //是否默认
				},
				dataValuesNull: {
					Id: 0,
					Consignee: '', //联系人
					Mobile: '', //手机号
					Province: '', //省
					City: '', //市
					Area: '', //区
					Address: '', //详细地址
					IsDefault: 0 //是否默认
				},
				addlist: [],
				layuibtn: true,
				defaultinput:true,
			};
		},
		mounted() {
			layui.use('form', () => {
				//获取表单元素
				var form = layui.form;
				//表单初始化 随后让Layui渲染一次
				$("#distpicker2").distpicker();
				form.render();
				//声明表单select选择事件
				form.on('select(brickType1)', (data) => {
					this.updata_address_select(data, '#address_sheng');
					form.render();
					//重新渲染一次
				});
				form.on('select(brickType2)', (data) => {
					this.updata_address_select(data, '#address_shi');
					form.render();
					//重新渲染一次
				});
				form.on('select(brickType3)', (data) => {
					this.updata_address_select(data, '#address_qv');
					form.render();
					//重新渲染一次
				});


			});
			this.addresslist()
		},

		methods: {
			defaultaddress(type) {
				if (type == 0) {
					this.dataValues.IsDefault = 1
				} else if (type == 1) {
					this.dataValues.IsDefault = 0
				}

			},
			//地址列表
			async addresslist() {
				var that = this
				const res = await this.postdata('/api/User/AddressList', {
					page: 1,
					limit: 20
				})
				if (res.code == 200) {
					this.addlist = res.data.items
				}
			},
			//设置默认地址
			async setAddressDefault(id) {
				const res = await this.postdata('/api/User/AddressDefault', {
					queryId: id
				})
				if (res.code == 200) {
					this.notifySucceed('设置成功');
					this.addresslist()
				}
			},
			//新增、修改地址
			async OpercateUserAddress() {
				var that = this
				var dataValuesNull = this.dataValuesNull
				layui.use(['form', 'util', 'laydate'], async () => {
					var form = layui.form;
					var layer = layui.layer;
					var util = layui.util;
					var laydate = layui.laydate;
					//表单取值
					var data = form.val('example');
					that.dataValues.Province = data.quiz1
					that.dataValues.City = data.quiz2
					that.dataValues.Area = data.quiz3
					if (this.dataValues.Province == '' || this.dataValues.City == '' || this.dataValues
						.Area == '') {
						this.notifyError('请选择地址');
						return false
					}
					if (this.dataValues.Address == '') {
						this.notifyError('请填写详细地址');
						return false
					}
					if (this.dataValues.Consignee == '') {
						this.notifyError('请填写收货人姓名');
						return false
					}
					if (this.dataValues.Mobile == '') {
						this.notifyError('请填写手机号码');
						return false
					}
					console.log(this.dataValues)
					const res = await this.postdata('/api/User/EditAddress', this.dataValues)
					if (res.code == 200) {
						this.notifySucceed('保存成功');
						this.addresslist()
						
						this.dataValues = dataValuesNull
					}
				})
			},
			EditAddress(item) {
				var that = this
				this.dataValues = {
					Id: item.Id,
					Consignee: item.Consignee, //联系人
					Mobile: item.Mobile, //手机号
					Province: item.Province, //省
					City: item.City, //市
					Area: item.Area, //区
					Address: item.Address, //详细地址
					IsDefault:item.IsDefault
				}
				this.defaultinput = false
				this.defaultinput = true
				console.log(this.dataValues)
				layui.use(['form', 'util', 'laydate'], () => {
					var form = layui.form;
					var layer = layui.layer;
					var util = layui.util;
					var laydate = layui.laydate;
					//表单取值
					var data = form.val('example');
					$("#distpicker2").distpicker();
					form.render();
					console.log(form)
					that.Editupdata_address_select(item.Province, '#address_sheng');
					form.render();

					that.Editupdata_address_select(item.City, '#address_shi');
					form.render();

					that.Editupdata_address_select(item.Area, '#address_qv');
					form.render();
				})


				that.layuibtn = false
			},
			async EditUserAddress() {
				var that = this
				var dataValuesNull = this.dataValuesNull
				layui.use(['form', 'util', 'laydate'], async () => {
					var form = layui.form;
					var layer = layui.layer;
					var util = layui.util;
					var laydate = layui.laydate;
					//表单取值
					var data = form.val('example');
					that.dataValues.Province = data.quiz1
					that.dataValues.City = data.quiz2
					that.dataValues.Area = data.quiz3
					if (this.dataValues.Province == '' || this.dataValues.City == '' || this.dataValues
						.Area == '') {
						this.notifyError('请选择地址');
						return false
					}
					if (this.dataValues.Consignee == '') {
						this.notifyError('请填写收货人姓名');
						return false
					}
					if (this.dataValues.Mobile == '') {
						this.notifyError('请填写手机号码');
						return false
					}
					if (this.dataValues.Address == '') {
						this.notifyError('请填写详细地址');
						return false
					}
					console.log(this.dataValues)
					const res = await this.postdata('/api/User/EditAddress', this.dataValues)
					if (res.code == 200) {
						this.notifySucceed('保存成功');
						this.addresslist()
						this.dataValues = dataValuesNull
						console.log(this.dataValues , dataValuesNull)
						that.layuibtn = true
					}
				})
			},
			//删除地址
			async DelUserAddress(id) {
				const res = await this.postdata('/api/User/DelUserAddress', {
					ids: [id]
				})
				if (res.code == 200) {
					this.notifySucceed('删除成功');
					this.addresslist()
				}
			},
			updata_address_select(data, id) {
				var val = data.value;
				var sel = $(id);
				sel.val(data.value);
				sel.change();
			},
			Editupdata_address_select(data, id) {
				var val = data;
				var sel = $(id);
				sel.val(data);
				sel.change();
			},
			Addressempty() {
				var that = this
				layui.use(['form', 'util', 'laydate'], function() {
					var form = layui.form;
					var layer = layui.layer;
					var util = layui.util;
					var laydate = layui.laydate;
					//表单取值

					form.val('example', {
						"username": "",
						"phone": "",
						"quiz1": "",
						"quiz2": "",
						"quiz3": "",
						"address": "",
						"dizhishow": false
					})
					//表单初始化 随后让Layui渲染一次
					$("#distpicker2").distpicker();
					form.render()

					that.updata_address_select(that.dataitems[that.cindex3].Province, '#address_sheng')
					form.render()

					that.updata_address_select(that.dataitems[that.cindex3].City, '#address_shi')
					form.render()

					that.updata_address_select(that.dataitems[that.cindex3].Area, '#address_qv')
					form.render()
				});

			}
		},
	};
</script>
<style scoped>
	/* 订单为空的时候显示的内容CSS */
	.layui-btn{
		background-color: #e23435;
	}
	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.uc-box .order-empty {
		width: 1225px;
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 558px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 124px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}


	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.next-table {
		position: relative;
		margin: 48px 0;
	}

	.next-table table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		background: #fff;
	}

	.next-table-header {
		margin-bottom: -20px;
		padding-bottom: 20px;
		overflow: auto;
		font-size: 12px;
	}

	.next-table th {
		padding: 0;
		background: #ebecf0;
		color: #333;
		text-align: left;
		font-weight: 400;
		border: 1px solid #dcdee3;
	}

	.next-table th .next-table-cell-wrapper {
		padding: 12px 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	.tAction .t-change {
		cursor: pointer;
	}
	.tAction .t-change:hover{
		color: #e23435;
	}
	.next-table-cell-wrapper .tAction {
		display: flex;
	}


	.next-table-body {
		font-size: 12px;
		position: relative;
	}

	.next-table-row {
		transition: all .1s linear;
		background: #fff;
		color: #333;
	}

	.next-table-cell.hovered,
	.next-table-row.hovered,
	.next-table-row.selected {
		background: #f2f3f7;
		color: #333;
	}

	.next-table table tr:first-child td {
		border-top-width: 0;
	}

	.next-table td {
		padding: 0;
		border: 1px solid #dcdee3;
	}

	.next-table td .next-table-cell-wrapper {
		padding: 12px 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	table .t-default{
		display: block;
		width: 80px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border: 1px solid #603c1d;
		border-radius: 3px;
		background: #603c1d;
		color: #fff;
		cursor: pointer;
	}
	
	table .t-delete,
	table .t-setDefault {
		cursor: pointer;
	}
	table .t-setDefault:hover {
		color: #e23435;
	}

	.redxx {
		color: #ff0000;
	}

	/* 订单为空的时候显示的内容CSS END */
	.layui-input-block{
		display: flex;
		align-items: center;
	}
	.layui-input{
		height: 30px;
	}
</style>
